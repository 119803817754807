.project-header {
  font-size: 40px;
  display: flex;
  justify-content: center;
  font-weight: 600;
}

.project-container {
  margin-top: 100px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  width: 65vw;
  margin: 100px 0px;
}

.project-link {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.link {
  text-decoration: none;
  color: black;
  border: 2px solid black;
  padding: 5px 5px;
  font: bold;
  border-radius: 30px;
  font-size: 18px;
  box-shadow: 0px 0px 7px 0px #9af2e2;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  margin-right: 10px;
}

.link:hover {
  transform: translateY(2px);
}

.highlight {
  color: #9af2e2;
  text-decoration: none;
}
.screenshot img {
  border: 30%;
  box-shadow: 1px gray;
  border-radius: 20px;
  width: 37vw;
}

.description {
  display: flex;
  flex-direction: column;
  padding: 5px 25px;
  width: 20vw;
}

.tags {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.tag {
  border: 2px solid black;
  box-shadow: 0px 0px 4px 0px #64f6dc;
  border-radius: 13px;
  height: 10px;
  padding: 4px 6px;
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin: 4px 4px;
  font-weight: 700;
}

.title {
  font-size: 34px;
  font: bolder;
  font-weight: 700;
}

.project-info {
  color: gray;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .project-container{
    margin-top: 50px;
  }
  .project-card {
    flex-direction: column !important;
    width: 80%;
    margin: 50px 0px;
  }

  .description {
    width: 100%;
  }

  .screenshot {
    margin-top: 20px;
  }

  .screenshot img {
    width: 100%;
  }

  .tags {
    display: none;
  }
}

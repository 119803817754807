/* .techstack-header{
    font-size: 40px;
    font-weight: 600;
    padding-left: 150px;
    padding-bottom: 50px;
    text-align:center;
    margin:5%;
    margin-bottom: 100px;
} */
/* .slideshow-1{
    position: relative;
    width: 100%;
    margin-top: 100px;
    margin-bottom: 100px;
   overflow-x:clip;
   margin-left:100px;
    margin-bottom:10%;
} */

.slideshow-1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
}
.images:hover {
  animation-play-state: paused;
}
.images {
  background: white;
  /* position: absolute; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* animation: slideshow-1 2.5s linear infinite;  */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

@keyframes slideshow-1 {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5%);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(0);
  }
}

.logo-card img {
  width: 4vw;
  height: 7vh;
  padding-bottom: 5px;
}

.logo-card {
  max-width: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 30px 30px 30px;
  box-shadow: 0 0 10px 1px #ebf6f7;
  margin-right: 20px;
}

@media screen and (max-width: 768px) {

    .logo-card img{
        width: 40px;
        height: 40px;
    }

    .logo-card{
        margin-bottom: 15px;
    }
    .icon-text{
        font-size: smaller;
    }

    .images{
        flex-direction: column;
    }

    .slideshow-1{
        margin-top: 100px;
        flex-direction: row;
    }

    .next-logo{
        width: 40px;
        height: 40px;
    }
}

.contact {
  padding-bottom: 100px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 200px;
}
.contact-header {
  font-size: 32px;
  font-weight: 600;
  padding-bottom: 50px;
  padding-left: 100px;
}
.contact-cards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.contact-card {
  height: 100%;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 0 20px #e4f8fb;
  padding: 17px;
  width: 25%;
  display: flex;
  align-items: center;
}

.contact-card img {
  width: 40px;
  padding-right: 20px;
}

.contact-card span {
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .contact-cards {
    flex-direction: column;
  }

  .contact{
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .contact-card {
    width: 80%;
    margin-bottom: 25px;
    padding: 10px 60px 10px 10px;
    justify-content: flex-start;
  }

  .contact-card span {

    font-size: 14px;
    font-weight: 600;
  }
}
